import { Stack, Badge, Typography } from "@mui/material";

const StatusIndicator = ({ status }: { status: boolean }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Badge color={status ? "success" : "warning"} variant="dot" />
      <Stack>
        <Typography
          textTransform="capitalize"
          fontWeight={600}
          color={"#2c2c2c"}
          fontSize={14}
        >
          {status ? "Active" : "Inactive"}
        </Typography>

        <div
          style={{
            height: 2,
            width: "100%",
            backgroundColor: status ? "#67be23" : "#fa8c16",
            borderRadius: 100,
          }}
          className={` h-[3px] w-full ${
            status ? " bg-green-500" : " bg-orange-600"
          } rounded-full`}
        />
      </Stack>
    </Stack>
  );
};
export default StatusIndicator;
