import { Stack, LinearProgress, Typography } from "@mui/material";
import { CustomLoading, AdministratorForm } from "components";
import { useAppSelector } from "redux/hooks";
import { adminsSelector } from "redux/admins/adminSlice";

const CreateAdministrator = () => {
  // Obtaining Admin [] data
  const { loading } = useAppSelector(adminsSelector);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Stack spacing={2}>
      <Typography fontSize={25} fontWeight={700}>
        Create New Administrator
      </Typography>

      {/* Stepper Indicator */}
      <AdministratorForm type={"create"} />
    </Stack>
  );
};

export default CreateAdministrator;
