import { LinearProgress, Box } from "@mui/material";

const CustomLoading = () => {
  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LinearProgress
        sx={{
          width: "50%", // Ancho del LinearProgress
        }}
      />
    </Box>
  );
};

export default CustomLoading;
