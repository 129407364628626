import { configureStore } from "@reduxjs/toolkit";
// import themeSlice from './theme/themeSlice';
import rulesReducer from "./rules/ruleSlice";
import lotReducer from "./lots/lotSlice";
import supervisorReducer from "./supervisors/supervisorSlice";
import eventsReducer from "./events/eventSlice";
import adminReducer from "./admins/adminSlice";

export const store = configureStore({
  reducer: {
    rules: rulesReducer,
    lots: lotReducer,
    supervisors: supervisorReducer,
    events: eventsReducer,
    admin: adminReducer,
    admins: adminReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["PATCH, lot/updateLot"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        // Ignore these paths in the state
        ignoredPaths: ["items.dates"],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
