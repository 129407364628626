import { useState } from "react";
import { EventAvailable, Cancel } from "@mui/icons-material";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";

import {
  Box,
  Collapse,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  Switch,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import CustomButton from "../CustomButton";
import EventCountdown from "./EventCountDown";
import { fetchLotById, lotsSelector } from "redux/lots/lotSlice";
import { useParams } from "@pankod/refine-react-router-v6";
import { createEvent, deleteEvent } from "redux/events/eventSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useToast } from "hooks";
import { CustomLoading } from "components";

const SpecialEventForm = ({ lotId }: { lotId: string }): JSX.Element => {
  const { lot, loading } = useAppSelector(lotsSelector);
  const params: any = useParams();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { toast } = useToast();

  const dispatch = useAppDispatch();

  //Lot Special Event
  const [specialEvent, setSpecialEvent] = useState<any>([]);

  const isValidForm = () => {
    // Validate empty fields
    if (!specialEvent.eventEndDate || !specialEvent.eventRate) {
      setErrorMessage("Please fill in all fields!");
      return false;
    }

    return true;
  };

  const handleAddSpecialEvent = () => {
    if (isValidForm()) {
      dispatch(
        createEvent({
          lotId: lotId || params.id,
          specialEvent: specialEvent,
          toast: toast,
        })
      );
      // reset data
      setSpecialEvent([{}]);
    } else {
      setError(true);
    }
  };
  const handleDeleteSpecialEvent = () => {
    dispatch(
      deleteEvent({ eventId: lot?.specialEvents[0]?._id, toast: toast })
    );
    setTimeout(() => {
      dispatch(fetchLotById({ lotId: lotId || params.id }));
    }, 700);
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box marginTop={5}>
        {lot?.specialEvents && lot.specialEvents.length > 0 ? (
          <Stack gap={3} flexDirection="column">
            <EventCountdown
              endDate={lot?.specialEvents[0].eventEndDate}
              eventActive={lot?.specialEvents[0].eventActive}
              eventRate={lot?.specialEvents[0].eventRate}
            />
            <CustomButton
              title="Cancel Event"
              handleClick={handleDeleteSpecialEvent}
              backgroundColor="#f0f0f0"
              style={{
                fontSize: 12,
                color: "#f00",
              }}
              icon={<Cancel />}
            />
          </Stack>
        ) : (
          <>
            <FormControlLabel
              sx={{ marginBottom: 1 }}
              control={
                <Switch
                  checked={specialEvent.eventActive}
                  onChange={(event: { target: { checked: any } }) =>
                    setSpecialEvent({
                      ...specialEvent,
                      eventActive: event.target.checked,
                    })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  color="warning"
                />
              }
              label={<Typography variant="h6">Special Event</Typography>}
              labelPlacement="start"
            />
            <Collapse in={specialEvent.eventActive}>
              <Stack
                direction="column"
                gap={3}
                alignItems="flex-start"
                mb={2}
                px={4}
              >
                <DemoItem label="End Date">
                  <MobileDateTimePicker
                    value={specialEvent.eventEndDate}
                    disabled={!specialEvent.eventActive}
                    onChange={(newDate) =>
                      setSpecialEvent({
                        ...specialEvent,
                        eventEndDate: newDate,
                      })
                    }
                    ampm={false}
                  />
                </DemoItem>
                <TextField
                  disabled={!specialEvent.eventActive}
                  required
                  label="Rate"
                  id="standard-start-adornment"
                  type="number"
                  color="warning"
                  onChange={(event: any) =>
                    event.target.value < 0
                      ? (event.target.value = 0)
                      : setSpecialEvent({
                          ...specialEvent,
                          eventRate: event.target.value,
                        })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">USD</InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 2 }}
                  variant="standard"
                />
                <CustomButton
                  disabled={!specialEvent.eventActive}
                  handleClick={handleAddSpecialEvent}
                  title="Create Event"
                  backgroundColor="#FA8C16"
                  style={{
                    fontSize: 12,
                    color: "#ffffff",
                  }}
                  icon={<EventAvailable />}
                />
              </Stack>
            </Collapse>
            <Typography color="red" variant="body1" fontWeight={700}>
              {errorMessage}
            </Typography>
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
};
export default SpecialEventForm;
