import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { fetchLotById } from "../lots/lotSlice";
import { URL_PROVIDER } from "utils/env";

export interface EventProps {
  eventActive: boolean;
  eventEndDate: any;
  eventRate: string;
}

interface SpecialEventState {
  specialEvents: EventProps[];
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

const initialState: SpecialEventState = {
  specialEvents: [],
  loading: false,
  error: false,
  errorMessage: "",
};

const eventSlice = createSlice({
  name: "events",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEvents: (state, { payload }) => {
      state.specialEvents = payload;
      state.loading = false;
      state.error = false;
    },
    // setLot: (state, { payload }) => {
    //   state.lot = payload;
    //   state.loading = false;
    //   state.error = false;
    // },
    // resetLot: (state) => {
    //   state.lot = initialState.lot;
    // },
    // setStepForm: (state, action) => {
    //   state.stepForm = action.payload;
    // },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const { setEvents, setError, setLoading } = eventSlice.actions;
export const eventsSelector = (state: { events: any }) => state.events;

export default eventSlice.reducer;

// create new event
export function createEvent({
  lotId,
  specialEvent,
  toast,
}: {
  lotId: string;
  specialEvent: EventProps;
  toast: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .post(`${URL_PROVIDER}/special-events`, {
        ...specialEvent,
        lotId: lotId,
      })
      .then((response) => {
        dispatch(setEvents(response.data.savedSpecialEvent));
        setTimeout(() => {
          dispatch(fetchLotById({ lotId: lotId })).then(() =>
            toast.success("New Special Event created!")
          );
        }, 700);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          // Si hay un mensaje de error en la respuesta del backend, lo mostramos en el toast
          toast.error("Error: " + err.response.data);
        } else {
          // Si no hay un mensaje de error específico, mostramos un mensaje de error genérico
          toast.error("An error occurred");
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };
}

// update an existing event
export function updateEvent({
  eventId,
  specialEvent,
}: {
  eventId: string;
  specialEvent: EventProps;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .patch(`${URL_PROVIDER}/special-events/${eventId}`, {
        ...specialEvent,
      })
      .then((response) => {
        dispatch(setLoading(false));
        toast.success("Event updated!");
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {
        toast.error("Error: " + err.message);
      });
  };
}
// delete a lot
export function deleteEvent({
  eventId,
  toast,
}: {
  eventId: string;
  toast: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .delete(`${URL_PROVIDER}/special-events/${eventId}`)
      .then((response) => {
        dispatch(setLoading(false));
        toast.success("Event deleted!");
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {
        toast.error("Error: " + err.message);
      });
  };
}
