import { useState } from "react";

import {
  TableRow,
  TableCell,
  IconButton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { Delete, Edit } from "@mui/icons-material";
import CustomButton from "../CustomButton";
import RulesCollapsible from "../Rules/RulesCollapsible";
import EventCountdown from "../SpecialEvents/EventCountDown";
import StatusIndicator from "../StatusIndicator";
import AvatarSupervisorsGroup from "./AvatarSupervisorsGroup";
import { RuleProps } from "redux/rules/ruleSlice";
import { EventProps } from "redux/events/eventSlice";
import { useAppDispatch } from "redux/hooks";
import { deleteLot } from "redux/lots/lotSlice";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { useToast } from "hooks";

function createData(
  id: string,
  alias: string,
  address: string,
  supervisors: [],
  status: boolean,
  rules: RuleProps[],
  specialEvents: EventProps[]
) {
  return {
    id,
    alias,
    address,
    supervisors,
    status,
    rules,
    specialEvents,
  };
}

const LotTableBody = (props: { lot: ReturnType<typeof createData> }) => {
  const { lot } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const isResponsive = useMediaQuery("(max-width: 960px)");
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleDeleteLot = ({ lotId }: { lotId: string }) => {
    const response = confirm("Are you sure you want to delete this Lot?");
    if (response) {
      dispatch(deleteLot({ lotId: lotId, toast: toast }));
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* Lot alias */}
        <TableCell style={{ fontWeight: 800, color: "#000", fontSize: 16 }}>
          {lot.alias}
        </TableCell>
        {/* Lot address */}
        <TableCell style={{ fontSize: 12, fontWeight: 500, opacity: 0.75 }}>
          {lot.address}
        </TableCell>
        {/* Supervisors */}
        <TableCell>
          <AvatarSupervisorsGroup supervisors={lot.supervisors} max={3} />
        </TableCell>
        {/* Status */}
        <TableCell>
          <StatusIndicator status={lot.status} />
        </TableCell>
        {/* Buttons Edit and Delete */}
        <TableCell>
          <Stack direction={isResponsive ? "column" : "row"} spacing={1}>
            <CustomButton
              handleClick={() => navigate(`/lots/edit/${lot.id}`)}
              fullWidth
              title={isResponsive ? "" : "Edit"}
              backgroundColor="#475BE8"
              icon={<Edit fontSize="small" />}
            />
            <CustomButton
              handleClick={() => handleDeleteLot({ lotId: lot?.id })}
              backgroundColor="#FF0000"
              fullWidth
              title={isResponsive ? "" : "Delete"}
              icon={<Delete fontSize="small" />}
            />
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        {/* Rules collapsibles or Special Event */}
        <TableCell
          style={{
            padding: 0,
          }}
          colSpan={7}
        >
          {/* If any special event is active, show it. If not, show the rules */}
          {lot?.specialEvents && lot.specialEvents.length > 0 ? (
            <EventCountdown
              endDate={lot?.specialEvents[0]?.eventEndDate}
              eventRate={lot?.specialEvents[0]?.eventRate}
              eventActive={lot?.specialEvents[0]?.eventActive}
            />
          ) : (
            <RulesCollapsible rules={lot.rules} openAll={open} />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default LotTableBody;
