import React, { useState } from "react";
import axios from "axios";
import { Search } from "@mui/icons-material";
import { CustomButton } from "..";
import { useAppDispatch } from "redux/hooks";
import { setLot } from "redux/lots/lotSlice";
import { useToast } from "hooks";

const SearchAddressButtton = ({ lot }: { lot: any }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const searchAddress = async () => {
    setLoading(true);
    const url = `https://nominatim.openstreetmap.org/search?q=${lot.address}&format=json`;

    try {
      const response = await axios.get(url);
      const data = response.data[0];
      dispatch(
        setLot({
          ...lot,
          latitude: data.lat,
          longitude: data.lon,
          address: data.display_name,
          alias: data.display_name.split(",")[1].trim(),
        })
      );
      toast.success("Address autofill successfully!");

      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        toast.error(
          "Error: Address not found, please try with another address"
        );
      }
    }
  };

  return (
    <>
      <CustomButton
        disabled={loading}
        title="Search"
        handleClick={searchAddress}
        icon={<Search />}
        backgroundColor="#FA8C16"
      />
    </>
  );
};
export default SearchAddressButtton;
