import { Stack, LinearProgress, Typography } from "@mui/material";

import { useCallback, useEffect } from "react";
import { fetchLotById, lotsSelector } from "redux/lots/lotSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useParams } from "@pankod/refine-react-router-v6";
import {
  CustomLoading,
  LotForm,
  RulesForm,
  StepperIndicator,
} from "components";

const EditLot = () => {
  // Obtaining Lot [] data
  const { loading, stepForm } = useAppSelector(lotsSelector);
  const dispatch = useAppDispatch();
  const params: any = useParams();

  const fetchLot = useCallback(() => {
    dispatch(fetchLotById({ lotId: params.id }));
  }, [dispatch, params.id]);

  useEffect(() => {
    fetchLot();
  }, [fetchLot]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    // <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Stack spacing={2}>
      {/* Stepper Indicator */}
      <Typography fontSize={25} fontWeight={700}>
        Edit Lot
      </Typography>
      <StepperIndicator />
      {stepForm === 0 ? <LotForm type="edit" /> : <RulesForm type="edit" />}
    </Stack>
  );
};

export default EditLot;
