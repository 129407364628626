import * as React from "react";

import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SupervisedUserCircle } from "@mui/icons-material";
import { StringAvatar } from "utils";

interface AvatarSupervisorsGroupProps {
  supervisors: string[];
  max: number;
}

const AvatarSupervisorsGroup: React.FC<AvatarSupervisorsGroupProps> = ({
  supervisors,
  max,
}) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <Stack justifyContent="center" alignItems="center">
      <Box>
        <Tooltip title="View supervisors list">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <SupervisedUserCircle fontSize="large" color="info" />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {supervisors.slice(0, max).map((supervisor, index) => (
            <MenuItem key={index} onClick={handleCloseUserMenu}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <StringAvatar name={supervisor} />
                <Typography textAlign="center" color="#8a8a8a">
                  {supervisor}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {/* {supervisors.slice(0, max).map((supervisor, index) => (
        <>
          <Tooltip key={index} title={supervisor} arrow>
            <StringAvatar name={supervisor} />
          </Tooltip>
        </>
      ))} */}
    </Stack>
  );
};

export default AvatarSupervisorsGroup;
