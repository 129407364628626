import { useState } from "react";

import { Close, Send } from "@mui/icons-material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {
  Box,
  FormControl,
  FormHelperText,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "../CustomButton";
import { toast } from "react-hot-toast";

const ForgotPasswordModal = ({
  openModal,
  setOpenModal,
}: {
  openModal: boolean;
  setOpenModal: any;
}) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = getAuth();

  const handleResetPassword = (e: any) => {
    e.preventDefault();
    setLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        toast.success(
          "Email sent successfully! Check your email to reset your password"
        );

        setTimeout(() => {
          setOpenModal(false);
          setLoading(false);
        }, 3000);
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });
  };

  return (
    <Modal open={openModal}>
      <form
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
        onSubmit={handleResetPassword}
      >
        <Box className="modal_add_rule">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            position="relative"
            width="100%"
          >
            <Typography fontSize={22} fontWeight={700}>
              Forgot Password?
            </Typography>

            <CustomButton
              handleClick={() => setOpenModal(false)}
              title=""
              icon={<Close />}
              backgroundColor="#2c2c2c"
              style={{
                position: "absolute",
                right: 0,
                minWidth: "auto",
                color: "#fcfcfc",
              }}
            />
          </Stack>
          <Stack justifyContent="space-around">
            <Typography
              fontSize={14}
              fontWeight={600}
              marginBottom={3}
              color="#8a8a8a"
            >
              Enter your email, so we can send you a link to reset your password
            </Typography>
            <FormControl>
              <FormHelperText
                sx={{
                  fontWeight: 700,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#11142d",
                }}
              >
                Email
              </FormHelperText>
              <TextField
                focused
                fullWidth
                required
                type="email"
                color="info"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
          </Stack>

          <CustomButton
            disabled={loading}
            fullWidth
            type="submit"
            title="Send Link"
            backgroundColor="#2c2c2c"
            icon={<Send />}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default ForgotPasswordModal;
