import StatusIndicator from "./StatusIndicator";

export const supervisorColumns = [
  { field: "id", headerName: "ID", width: 60, editable: false },
  {
    field: "name",
    headerName: "Name",
    width: 120,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
  },

  {
    field: "phone",
    headerName: "Phone",
    width: 130,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,

    renderCell: (params: any) => {
      return <StatusIndicator status={params.row.status} />;
    },
  },
];

export const usersColumns = [
  { field: "id", headerName: "ID", width: 60, editable: false },
  {
    field: "displayName",
    headerName: "Name",
    width: 170,
  },

  {
    field: "email",
    headerName: "Email",
    width: 170,
  },

  {
    field: "phone",
    headerName: "Phone",
    width: 130,
  },
];

export const lotColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "alias",
    headerName: "Alias",
    editable: true,
    width: 150,
  },
  {
    field: "address",
    headerName: "Address",
    editable: true,
    width: 150,
  },
  {
    field: "admin",
    headerName: "Admin",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params: any) => {
      return <Status params={params} />;
    },
  },
];

export const rulesColumns = [
  {
    field: "start_time",
    headerName: "From",
    width: 150,
  },
  {
    field: "end_time",
    headerName: "To",
    width: 150,
  },
  {
    field: "days",
    headerName: "Days",
    width: 150,
  },
  {
    field: "rate",
    headerName: "Rate",
    width: 100,
  },
];

export const historyColumns = [
  { field: "id", headerName: "ID", width: 60, editable: false },
  {
    field: "date",
    headerName: "Date",
    width: 170,
  },

  {
    field: "lot",
    headerName: "Lot",
    width: 170,
  },

  {
    field: "payedFee",
    headerName: "Payed Fee",
    width: 130,
  },
];

export const Status = ({ params }: { params: any }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <p
      style={{
        background: params.row.status === true ? "#00FF00" : "gray",
        height: 10,
        width: 10,
        borderRadius: 10,
        marginRight: 5,
      }}
      className="rounded-full h-3 w-3"
    />
    {params.row.status === true ? (
      <p style={{ textTransform: "capitalize", fontWeight: 600 }}>Active</p>
    ) : (
      <p style={{ textTransform: "capitalize", fontWeight: 600 }}>Inactive</p>
    )}
  </div>
);

export const CheckboxDays = [
  {
    order: 0,
    name: "mon",
    checked: false,
  },
  {
    order: 1,
    name: "tue",
    checked: false,
  },
  {
    order: 2,
    name: "wed",
    checked: false,
  },
  {
    order: 3,
    name: "thu",
    checked: false,
  },
  {
    order: 4,
    name: "fri",
    checked: false,
  },
  {
    order: 5,
    name: "sat",
    checked: false,
  },
  {
    order: 6,
    name: "sun",
    checked: false,
  },
];

export const adminColumns = [
  { field: "id", headerName: "ID", width: 60, editable: false },
  {
    field: "name",
    headerName: "Name",
    width: 120,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
  },

  {
    field: "phone",
    headerName: "Phone",
    width: 130,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,

    renderCell: (params: any) => {
      return <StatusIndicator status={params.row.status} />;
    },
  },
];
