import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createRule, resetRule, rulesSelector } from "../redux/rules/ruleSlice";
import { fetchLotById } from "../redux/lots/lotSlice";
import { calculateHours, getRandomHexColor } from "../utils";
import useToast from "./useToast";
import { useAppDispatch } from "redux/hooks";

export const useRuleHandler = ({
  lotId,
  currentDay,
  toggleModal,
  isValidHours,
}: {
  lotId: string;
  currentDay: string;
  toggleModal: () => void;
  isValidHours: boolean;
}) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const { rule } = useSelector(rulesSelector);
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const handleCloseModal = () => {
    // Close rule modal
    toggleModal();
    // reset data
    setErrorMessage("");
    dispatch(resetRule());
    setLocalLoading(false);

    // setSelectedHours([]);
  };

  const isValidForm = () => {
    if (!rule.start_time || !rule.end_time || !rule.rate) {
      setErrorMessage("Please fill in all fields!");
      return false;
    }
    // Validate remaining hours
    if (!isValidHours) {
      setErrorMessage("Time range exceeds remaining hours!");
      return false;
    }

    return true;
  };

  const handleRule = async () => {
    if (isValidForm()) {
      setLocalLoading(true);
      const newRule = {
        ...rule,
        hours: calculateHours(rule.start_time, rule.end_time),
        color: getRandomHexColor(),
        day: currentDay,
      };
      await dispatch(createRule({ lotId: lotId, toast: toast, rule: newRule }));

      await dispatch(fetchLotById({ lotId: lotId }));
      setTimeout(() => {
        dispatch(fetchLotById({ lotId: lotId }));
      }, 700);
      handleCloseModal();
    } else {
      setError(true);
    }
  };

  return {
    errorMessage,
    handleRule,
    error,
    localLoading,
    handleCloseModal,
  };
};
