import { useEffect, useState } from "react";
import moment from "moment";
import { rulesSelector } from "../redux/rules/ruleSlice";
import { lotsSelector } from "../redux/lots/lotSlice";
import { useAppSelector } from "redux/hooks";

const usePieChartValues = ({ selectedDay }: { selectedDay: string }) => {
  const [isValidHours, setIsValidHours] = useState(true);
  const { rules, rule } = useAppSelector(rulesSelector);
  const { lot } = useAppSelector(lotsSelector);

  let colors: any[] = [];
  let series: any[] = [];
  let remainingHours = 0;
  let totalHours = 0;
  let rulesHours = [];
  let originalRemainingHours;

  if (lot.rules && lot.rules.map) {
    totalHours = 24;

    rulesHours = lot?.rules
      .filter((rule: { day: string }) => rule.day === selectedDay)
      .map((rule: { hours: any }) => Math.round(Math.abs(rule?.hours || 0)));

    originalRemainingHours =
      totalHours - rulesHours.reduce((acc: any, cur: any) => acc + cur, 0);

    remainingHours = originalRemainingHours;

    // Creamos un array con los colores para cada regla en localRules
    const rulesColors = lot?.rules
      .filter((rule: { day: string }) => rule.day === selectedDay)
      .map((rule: { color: any }) => rule?.color);
    // const rulesColors = rules.map((rule: any) => rule?.color);

    // Concatenamos el color #D7D7D7 al final del array rulesColors
    colors = [...rulesColors, "#D7D7D7"];
    series = [...rulesHours, remainingHours];
  } else {
    // Manejar el caso en que rules no es un objeto iterable
  }

  useEffect(() => {
    const start = moment(rule.start_time, "HH:mm");
    const end = moment(rule.end_time, "HH:mm");
    const differenceInHours = Math.abs(end.diff(start, "hours"));

    if (differenceInHours > remainingHours) {
      setIsValidHours(false);
    } else {
      setIsValidHours(true);
    }
  }, [rule]);
  return { remainingHours, isValidHours, colors, series, totalHours };
};

export default usePieChartValues;
