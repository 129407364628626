import { Stack, LinearProgress, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  fetchAdminById,
  adminsSelector,
} from "redux/admins/adminSlice";
import { useParams } from "@pankod/refine-react-router-v6";
import { CustomLoading, AdministratorForm } from "components";

const EditAdministrator = () => {
  // Obtaining Admin [] data
  const { loading } = useAppSelector(adminsSelector);
  const dispatch = useAppDispatch();
  const params: any = useParams();

  const fetchAdmin = useCallback(() => {
    dispatch(fetchAdminById({ adminId: params.id }));
  }, [dispatch, params.id]);

  useEffect(() => {
    fetchAdmin();
  }, [fetchAdmin]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Stack spacing={2}>
      <Typography fontSize={25} fontWeight={700}>
        Edit Administrator
      </Typography>
      <AdministratorForm type={"edit"} />
    </Stack>
  );
};

export default EditAdministrator;
