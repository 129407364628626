import { Add, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "../CustomButton";
import TimeRange from "./TimeRange";
import { useParams } from "@pankod/refine-react-router-v6";
import { rulesSelector, setRule } from "redux/rules/ruleSlice";
import { useRuleHandler } from "hooks";
import { useAppDispatch } from "redux/hooks";

const AddNewRuleModal = ({
  lotId,
  currentDay,
  toggleModal,
  rulesModal,
  isValidHours,
}: {
  lotId: string;
  currentDay: string;
  toggleModal: () => void;
  rulesModal: boolean;
  isValidHours: boolean;
}): JSX.Element => {
  const params: any = useParams();
  const dispatch = useAppDispatch();
  const { rule } = useSelector(rulesSelector);
  const { localLoading, handleCloseModal, handleRule, errorMessage } =
    useRuleHandler({
      lotId: lotId || params.id,
      currentDay: currentDay,
      toggleModal: toggleModal,
      isValidHours: isValidHours,
    });

  if (localLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <CircularProgress color="inherit" size={100} />
      </Box>
    );
  }

  return (
    <Modal open={rulesModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        bgcolor={"#fefefe"}
        borderRadius={4}
        p={4}
        width={["80%", "auto"]}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          position="relative"
          width="100%"
          mb={4}
        >
          <Typography variant="h6">
            Add rules for{" "}
            <span style={{ color: "#000000", fontWeight: 900 }}>
              {currentDay}
            </span>
          </Typography>
          <CustomButton
            handleClick={handleCloseModal}
            title=""
            icon={<Close />}
            backgroundColor="transparent"
            style={{
              position: "absolute",
              right: -20,
              top: -20,
              minWidth: "auto",
              color: "#2c2c2c",
            }}
          />
        </Stack>

        <Stack
          justifyContent="center"
          alignItems="center"
          boxShadow={1}
          bgcolor="#fff"
          gap={2}
          p={2}
          borderRadius={2}
        >
          {/* Selector start_time and end_time  */}
          <TimeRange rule={rule} currentDay={currentDay} />

          <TextField
            required
            label="Rate"
            name="rate"
            id="standard-start-adornment"
            type="number"
            fullWidth
            value={rule.rate}
            onChange={(event: any) =>
              event.target.value < 0
                ? (event.target.value = 0)
                : dispatch(setRule({ ...rule, rate: event.target.value }))
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
            inputProps={{ maxLength: 2 }}
            variant="standard"
          />
          <Typography color="red" variant="body1" fontWeight={700}>
            {errorMessage}
          </Typography>
          <CustomButton
            handleClick={handleRule}
            backgroundColor={"#2c2c2c"}
            title="Add"
            icon={<Add />}
          />
        </Stack>
      </Box>
    </Modal>
  );
};
export default AddNewRuleModal;
