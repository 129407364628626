"use client";
import { useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import { Stack, Typography, Modal, Box } from "@mui/material";
import { CustomButton, UserHistoryGrid } from "components";
import axios from "axios";
import { URL_PROVIDER } from "utils/env";

const UserHistoryModal = ({
  historyModalOpen,
  toggleModal,
  selectedUserHistory,
}: {
  historyModalOpen: boolean;
  toggleModal: () => void;
  selectedUserHistory: {
    displayName: string;
    id: string;
    email: string;
  } | null;
}) => {
  interface User {
    id: string;
    displayName: string;
    email: string;
    phone: string;
  }

  const [usersList, setUsersList] = useState<User[]>([
    { id: "", displayName: "", email: "", phone: "" },
  ]);

  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  console.log("selected user", selectedUserHistory);

  const paymentsFiltered = payments.filter((item: any) => {
    console.log(item.metadata);

    return (
      item.metadata.uid === selectedUserHistory?.id &&
      item.status === "succeeded"
    );
  });

  useEffect(() => {
    setLoading(true);
    // Obtener el historial de pagos desde el backend
    axios
      .get(`${URL_PROVIDER}/payment/history`)
      .then((response) => {
        setPayments(response.data.payments);
        setLoading(false);
      })
      .catch((error) => console.error(error.error));
  }, []);

  return (
    <Modal open={historyModalOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        overflow={"scroll"}
        bgcolor={"#fefefe"}
        borderRadius={2}
        p={2}
        width={["90%", "90%"]}
        maxHeight={"80%"}
        minHeight={"80%"}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          position="relative"
          width="100%"
          mb={2}
        >
          <Typography fontSize={20} fontWeight={700}>
            History of{" "}
            <span style={{ color: "#000000", fontWeight: 900 }}>
              {selectedUserHistory?.displayName}
            </span>
          </Typography>
          <Typography fontSize={14} fontWeight={700}>
            <span style={{ color: "#2c2c2c", fontWeight: 900 }}>
              {selectedUserHistory?.email}
            </span>
          </Typography>
          <CustomButton
            handleClick={toggleModal}
            title=""
            icon={<Close />}
            style={{
              position: "absolute",
              right: -15,
              top: -15,
              minWidth: "auto",
              color: "#000",
            }}
            backgroundColor="transparent"
          />
        </Stack>
        {paymentsFiltered.length > 0 ? (
          <UserHistoryGrid data={paymentsFiltered} />
        ) : (
          <Typography textAlign="center" mt={12}>
            No Payment History to show
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default UserHistoryModal;
