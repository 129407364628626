import { Button } from "@pankod/refine-mui";

import { CustomButtonProps } from "interfaces/common";

const CustomButton = ({
  type,
  title,
  backgroundColor = "#000",
  fullWidth,
  icon,
  handleClick,
  disabled,
  style,
  align,
}: CustomButtonProps) => {
  return (
    <Button
      disabled={disabled}
      type={type === "submit" ? "submit" : "button"}
      style={{
        backgroundColor: disabled ? "lightgray" : backgroundColor,
        cursor: disabled ? "none" : "pointer",
      }}
      sx={{
        justifyContent: align ? align : "center",
        flex: fullWidth ? 1 : "unset",
        paddingX: ".5rem",
        paddingY: ".3rem",
        width: fullWidth ? "100%" : "fit-content",
        maxWidth: 130,
        color: "#ffffff",
        fontSize: 14,
        gap: "5px",
        textTransform: "capitalize",
        height: "auto",
        borderRadius: ".3rem",
        "&:hover": {
          opacity: 0.9,
          backgroundColor,
        },
        ...style,
      }}
      onClick={handleClick}
    >
      {icon}
      {title}
    </Button>
  );
};

export default CustomButton;
