import { Avatar } from "@mui/material";

interface StringAvatarProps {
  name: string;
}

const StringAvatar: React.FC<StringAvatarProps> = ({ name }) => {
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: 14,
      },
      children: `${name.slice(0, 1).toUpperCase()}`,
    };
  };

  return <Avatar style={{ width: 24, height: 24 }} {...stringAvatar(name)} />;
};

export default StringAvatar;
