import React, { useState } from "react";
import CustomButton from "../CustomButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";

import StatusIndicator from "../StatusIndicator";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  supervisorsSelector,
  setSupervisor,
  updateSupervisor,
  createSupervisor,
} from "redux/supervisors/supervisorSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useToast } from "hooks";
import { useNavigate } from "@pankod/refine-react-router-v6";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SupervisorForm = ({ type }: { type: string }) => {
  const dispatch = useAppDispatch();
  const params: any = useParams();
  const { supervisor, loading } = useAppSelector(supervisorsSelector);
  const { toast } = useToast();
  const navigate = useNavigate();
  const [phoneData, setPhoneData] = useState({
    phoneNumber: "",
    countryCode: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(Boolean(supervisor?.status));
  // const router = useRouter();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    dispatch(
      setSupervisor({
        ...supervisor,
        [name]: value,
      })
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (type === "create") {
      dispatch(
        createSupervisor({
          supervisor: supervisor,
          toast: toast,
        })
      );
    } else {
      dispatch(
        updateSupervisor({
          supervisor: supervisor,
          supervisorId: params.id || supervisor.id,
          toast: toast,
        })
      );
    }
    navigate("/supervisors");
  };
  console.log(phoneData);
  console.log("SUPERVISORS: ", supervisor);

  return (
    <Box>
      <Box borderRadius={2} padding={4} bgcolor="#fcfcfc">
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          onSubmit={handleSubmit}
        >
          <FormControl sx={{ gap: 5 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                focused
                fullWidth
                required
                label="Name"
                color="info"
                variant="outlined"
                name="name"
                value={supervisor.name}
                onChange={handleInputChange}
              />
              <TextField
                size="small"
                focused
                fullWidth
                label="Last Name"
                color="info"
                variant="outlined"
                name="lastName"
                value={supervisor.lastName}
                onChange={handleInputChange}
              />
            </Stack>
            <TextField
              size="small"
              focused
              fullWidth
              disabled={type === "edit" ? true : false}
              required
              label="Email"
              type="email"
              color="info"
              variant="outlined"
              name="email"
              value={supervisor.email}
              onChange={handleInputChange}
            />

            <PhoneInput
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              country={"US"}
              disabled={type === "edit"}
              value={supervisor?.phoneLada}
              placeholder="Phone Number"
              onChange={(phone) => {
                dispatch(
                  setSupervisor({
                    ...supervisor,
                    phoneLada: `+${phone}`,
                  })
                );
              }}
            />

            {type === "create" && (
              <>
                <FormControl required focused variant="outlined" size="small">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    color="info"
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    color="info"
                    type={showPassword ? "text" : "password"}
                    id="outlined-adornment-password"
                    label="Password"
                    required
                    name="password"
                    value={supervisor.password}
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </>
            )}

            {/* <Status Selector /> */}
            <FormControlLabel
              sx={{
                width: 225,
                boxShadow: 2,
                marginLeft: 1,
                borderRadius: 10,
              }}
              control={
                <Switch
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    dispatch(
                      setSupervisor({ ...supervisor, status: e.target.checked })
                    );
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  color="primary"
                />
              }
              label={
                <Stack direction="row" alignItems="center" gap={2}>
                  <Typography color="#000">Status: </Typography>
                  <StatusIndicator status={supervisor?.status} />
                </Stack>
              }
              labelPlacement="end"
            />

            <CustomButton
              disabled={loading}
              type="submit"
              fullWidth
              title={loading ? "Submitting..." : "Submit"}
              backgroundColor="#475be8"
            />
          </FormControl>
        </form>
      </Box>
    </Box>
  );
};

export default SupervisorForm;
