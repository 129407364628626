"use client";
import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import { LotProps, setLot } from "redux/lots/lotSlice";
import {
  fetchSupervisors,
  supervisorsSelector,
} from "redux/supervisors/supervisorSlice";
import { useAppDispatch } from "redux/hooks";

const SupervisorsSelector = ({ lot }: { lot: LotProps }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const supervisorList = lot?.supervisors;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSupervisors());
  }, []);

  const { supervisors, loading } = useSelector(supervisorsSelector);

  const handleChange = (event: SelectChangeEvent<typeof supervisorList>) => {
    const {
      target: { value },
    } = event;
    dispatch(
      setLot({
        ...lot,
        supervisors: typeof value === "string" ? value.split(",") : value,
      })
    );
  };

  return (
    <FormControl sx={{ width: "100%" }} focused required color="secondary">
      <InputLabel id="demo-multiple-name-label">Supervisors</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={lot?.supervisors || []}
        onChange={handleChange}
        input={<OutlinedInput label="Supervisors" />}
        MenuProps={MenuProps}
      >
        {supervisors.map((supervisor: any) => (
          <MenuItem key={supervisor.id} value={supervisor.email}>
            {supervisor.email}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SupervisorsSelector;
