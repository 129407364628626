import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Typography, Box, Stack } from "@mui/material";

interface EventCountdownProps {
  endDate: Date;
  eventRate: string;
  eventActive: boolean;
}

const EventCountdown: React.FC<EventCountdownProps> = ({
  endDate,
  eventRate,
  eventActive,
}) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    let timeLeft: { [key: string]: number } = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <Typography
        variant="h5"
        textAlign="center"
        fontWeight={800}
        key={interval}
        color="#2c2c2c"
      >
        {timeLeft[interval]} {interval}
      </Typography>
    );
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {timerComponents.length ? (
        <Box
          boxShadow={2}
          justifyContent="center"
          sx={{
            background:
              "linear-gradient(to bottom , #1e36e8 , #475BE8, #6878EF  )",
            flex: 1,
          }}
          alignItems="center"
          borderRadius={2}
          padding={1}
        >
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <Typography fontSize={16} color="#fcfcfc" fontWeight={600}>
              🔥 Special Event 🔥
            </Typography>

            <Typography variant="h4" fontWeight={800} color="#fff">
              $ {eventRate}
            </Typography>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={1}
              bgcolor="#fcfcfc"
              borderRadius={2}
              paddingX={3}
              paddingY={1}
            >
              <Typography color="#8a8a8a">Ends in:</Typography>
              {timerComponents}
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box
          boxShadow={2}
          justifyContent="center"
          sx={{
            background:
              "linear-gradient(to bottom , #1e36e8 , #475BE8, #6878EF  )",
            flex: 1,
          }}
          alignItems="center"
          borderRadius={2}
        >
          <Typography
            variant="h4"
            textAlign="center"
            color="#fcfcfc"
            paddingY={1}
          >
            Special event ended!
          </Typography>
          <Typography
            variant="h6"
            textAlign="center"
            color="#fcfcfc"
            paddingY={1}
          >
            reload the page to see the Lot rules
          </Typography>
        </Box>
      )}
    </LocalizationProvider>
  );
};

export default EventCountdown;
