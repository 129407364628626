import { useState } from "react";

import { ArrowCircleRight } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  Stack,
  Typography,
  TextField,
  LinearProgress,
} from "@mui/material";
import { useNavigate, useParams } from "@pankod/refine-react-router-v6";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  createLot,
  lotsSelector,
  setLot,
  updateLot,
} from "redux/lots/lotSlice";
import SearchAddressButtton from "./SearchAddressButton";
import CustomButton from "components/CustomButton";
import StatusIndicator from "components/StatusIndicator";
import SupervisorsSelector from "components/Supervisors/SupervisorsSelector";
import { useToast } from "hooks";
import CustomLoading from "components/CustomLoading";

const LotForm = ({ type }: { type: string }) => {
  const params: any = useParams();

  // Obtaining Lot [] data
  const { lot, loading } = useAppSelector(lotsSelector);
  const navigate = useNavigate();
  const { toast } = useToast();

  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(Boolean(lot?.status));

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    dispatch(
      setLot({
        ...lot,
        [name]: value,
      })
    );
  };

  const handleSubmit = async () => {
    if (type === "create") {
      await dispatch(createLot({ lot: lot, toast: toast }));
    } else {
      await dispatch(updateLot({ lotId: params.id, lot: lot, toast: toast }));
    }
  };

  // simple validation for empty fields
  const isLotEmpty =
    !lot.address ||
    !lot.latitude ||
    !lot.longitude ||
    !lot.alias ||
    (lot.supervisors && lot.supervisors.length === 0);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Stack
      bgcolor="#ffffff"
      borderRadius={2}
      boxShadow={2}
      px={2}
      py={4}
      overflow="hidden"
      spacing={4}
      sx={{ minWidth: "100%" }}
    >
      <form
        style={{
          flexDirection: "column",
          display: "flex",
          width: "100%",
        }}
        onSubmit={handleSubmit}
      >
        {/* Lot Form */}
        <FormControl sx={{ gap: 5 }} required>
          <Stack direction="row" gap={3}>
            <TextField
              label="Address"
              type="text"
              id="address"
              name="address"
              value={lot.address}
              onChange={handleInputChange}
              size="small"
              focused
              fullWidth
              required
              variant="outlined"
            />
            <SearchAddressButtton lot={lot} />
          </Stack>
          <Stack direction="row" gap={3}>
            <TextField
              label="Latitude"
              type="number"
              id="latitude"
              name="latitude"
              value={lot.latitude}
              onChange={handleInputChange}
              size="small"
              focused
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label="Longitude"
              type="number"
              id="longitude"
              name="longitude"
              value={lot.longitude}
              onChange={handleInputChange}
              size="small"
              focused
              fullWidth
              required
              variant="outlined"
            />
          </Stack>
          <TextField
            label="Alias"
            type="text"
            id="alias"
            name="alias"
            value={lot.alias}
            onChange={handleInputChange}
            size="small"
            focused
            fullWidth
            required
            variant="outlined"
          />

          {/* <Supervisors Selector /> */}
          <SupervisorsSelector lot={lot} />

          {/* <Status Selector /> */}
          <FormControlLabel
            sx={{
              width: 225,
              boxShadow: 2,
              marginLeft: 1,
              borderRadius: 10,
            }}
            control={
              <Switch
                checked={checked}
                onChange={(e) => {
                  setChecked(e.target.checked);
                  dispatch(setLot({ ...lot, status: e.target.checked }));
                }}
                inputProps={{ "aria-label": "controlled" }}
                color="primary"
              />
            }
            label={
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography color="#000">Status: </Typography>
                <StatusIndicator status={lot?.status} />
              </Stack>
            }
            labelPlacement="end"
          />

          <CustomButton
            handleClick={handleSubmit}
            title="Next"
            type="submit"
            icon={<ArrowCircleRight />}
            disabled={loading || isLotEmpty}
          />
        </FormControl>
      </form>
    </Stack>
  );
};

export default LotForm;
