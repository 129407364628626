import { SetStateAction, useState } from "react";
import {
  Container,
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@pankod/refine-mui";
import { Lock } from "@mui/icons-material";

import { ReactComponent as Logo } from "../assets/logo.svg";
import { CustomButton, CustomLoading, ForgotPasswordModal } from "components";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "@pankod/refine-react-router-v6";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { URL_PROVIDER } from "utils/env";
import { useToast } from "hooks";
import { useDispatch } from "react-redux";
import { adminSelector, signInAdmin } from "redux/admins/adminSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export const Login: React.FC = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { toast } = useToast();
  const dispatch = useAppDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const navigate = useNavigate();

  const handleLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      signInAdmin({
        email: email,
        password: password,
        toast,
        navigate,
      })
    );
  };

  return (
    <Container
      style={{
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "row",
        margin: 0,
        padding: 0,
      }}
    >
      <Container
        className="login_logo"
        component="main"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          minWidth: "50vw",
          backgroundColor: "#2c2c2c",
        }}
      >
        <Logo fill="white" width="350px" />
      </Container>
      <Container
        className="login_form"
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          minWidth: "50vw",
          backgroundColor: "#fcfcfc",
          alignItems: "center",
        }}
      >
        <Box mt={2} borderRadius="15px" padding="15px">
          <Typography
            fontSize={30}
            fontWeight={800}
            color="#000000"
            textAlign="center"
            marginBottom={3}
          >
            Login
          </Typography>
          {/* <GoogleButton /> */}
          <form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
            onSubmit={handleLogin}
          >
            <FormControl>
              <FormHelperText
                sx={{
                  fontWeight: 700,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#11142d",
                }}
              >
                Email
              </FormHelperText>
              <TextField
                fullWidth
                required
                id="outlined-basic"
                color="info"
                type="email"
                variant="outlined"
                placeholder="example@domain.com"
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setEmail(e.target.value)
                }
              />
            </FormControl>

            <FormControl>
              <FormHelperText
                sx={{
                  fontWeight: 700,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#11142d",
                }}
              >
                Password
              </FormHelperText>
              <OutlinedInput
                required
                type={showPassword ? "text" : "password"}
                color="info"
                placeholder="* * * * * * * *"
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setPassword(e.target.value)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {/* <CustomButton
              handleClick={() => setOpenModal(true)}
              title="Forgot Password?"
              icon={<Lock fontSize="inherit" />}
              backgroundColor="transparent"
              fullWidth
              style={{
                fontSize: 12,
                alignSelf: "flex-end",
                color: "#475be8",
                minWidth: " 100%",
                flex: 1,
              }}
            /> */}

            <CustomButton
              fullWidth
              type="submit"
              title={"Submit"}
              backgroundColor="#475be8"
              style={{ marginTop: 5 }}
            />
          </form>
        </Box>
      </Container>
      <ForgotPasswordModal openModal={openModal} setOpenModal={setOpenModal} />
    </Container>
  );
};
