import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { lotsSelector, setStepForm } from "redux/lots/lotSlice";

const StepperIndicator = ({
  disabled,
  type,
}: {
  disabled?: boolean;
  type?: string;
}) => {
  const dispatch = useAppDispatch();
  const { stepForm } = useAppSelector(lotsSelector);
  const steps = [`Lot`, `Rules`];
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return stepForm === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : stepForm + 1;
    dispatch(setStepForm(newActiveStep));
  };

  const handleBack = () => {
    dispatch(setStepForm((prevActiveStep: number) => prevActiveStep - 1));
  };

  const handleStep = (step: number) => () => {
    dispatch(setStepForm(step));
    //dispatch(setStepForm(step));
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[stepForm] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    dispatch(setStepForm(0));
    setCompleted({});
  };
  return (
    <Box sx={{ width: "100%" }} mb={5}>
      <Stepper nonLinear activeStep={stepForm}>
        {steps.map((label, index) => (
          <Step
            disabled={disabled}
            key={label}
            completed={stepForm !== 0 && type === "Create" ? true : false}
          >
            <StepButton disabled={disabled} onClick={handleStep(index)}>
              <Typography fontSize={15} fontWeight={600} color="gray">
                {label}
              </Typography>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperIndicator;
