import { Stack, LinearProgress, Typography } from "@mui/material";
import {
  CustomLoading,
  LotForm,
  RulesForm,
  StepperIndicator,
} from "components";
import { useAppSelector } from "redux/hooks";
import { lotsSelector } from "redux/lots/lotSlice";

const CreateLot = () => {
  // Obtaining Lot [] data
  const { loading, stepForm } = useAppSelector(lotsSelector);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Stack spacing={2}>
      <Typography fontSize={25} fontWeight={700}>
        Create New Lot
      </Typography>
      {/* Stepper Indicator */}
      <StepperIndicator disabled />
      {stepForm === 0 ? <LotForm type="create" /> : <RulesForm type="create" />}
    </Stack>
  );
};

export default CreateLot;
