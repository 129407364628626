import { Add, Delete, Edit } from "@mui/icons-material";

import {
  Stack,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { CustomButton, CustomLoading } from "components";
import { adminColumns } from "components/columns";
import { auth } from "../../firebase";

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
    deleteAdmin,
    fetchAdmins,
    resetAdmin,
    adminsSelector,
} from "redux/admins/adminSlice";
import { useToast } from "hooks";

const Administrators = () => {
  const { admins, loading, adminUser } = useAppSelector(adminsSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    dispatch(fetchAdmins());
    dispatch(resetAdmin());
  }, []);

  const handleDeleteAdmin = ({
    adminId,
  }: {
    adminId: string;
  }) => {
    const response = confirm(
      "Are you sure you want to delete this Admin?"
    );
    if (response) {
      dispatch(deleteAdmin({ adminId: adminId, toast: toast }));
    }
  };

  const user: any = auth.currentUser;
  const isResponsive = useMediaQuery("(max-width: 960px)");
    const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 175,

      renderCell: (params: any) => {
        return (
          <Stack direction="row">
            <CustomButton
              fullWidth
              style={{ minWidth: 60, height: 30, fontSize: 12 }}
              title={isResponsive ? "" : "Edit"}
              handleClick={() => navigate(`/admins/edit/${params.id}`)}
              backgroundColor="#475BE8"
              icon={<Edit fontSize="small" />}
              disabled={adminUser.id != params.id ? false : true}
            />
            <CustomButton
              fullWidth
              style={{ minWidth: 60, height: 30, fontSize: 12, marginLeft: 1 }}
              title={isResponsive ? "" : "Delete"}
              handleClick={() => {
                handleDeleteAdmin({
                  adminId: params.id,
                });
              }}
              backgroundColor="#FF0000"
              icon={<Delete fontSize="small" />}
              disabled={adminUser.id != params.id ? false : true}
            />
          </Stack>
        );
      },
    },
  ];
  const detailsRows = admins?.map((item: any) => {
    return {
      id: item.id,
      email: item.email,
      name: item.name,
      lastName: item.lastName,
      phone: item.phoneLada,
      status: item.isActive,
    };
  });

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Stack gap={2}>
      <Stack direction={"row"} spacing={4} justifyContent="space-between">
        <Typography fontSize={25} fontWeight={700}>
          Administrators
        </Typography>

        <CustomButton
          title="Add"
          icon={<Add />}
          handleClick={() => navigate("/admins/create")}
        />
      </Stack>
      <Stack bgcolor="#fcfcfc" padding={2} borderRadius={2} width="100%">
        <DataGrid
          autoHeight
          rows={detailsRows}
          columns={adminColumns.concat(actionColumn)}
          checkboxSelection={false}
        />
      </Stack>
    </Stack>
  );
};

export default Administrators;
