import { ClearSharp, Add, Refresh } from "@mui/icons-material";
import {
  Stack,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CustomButton from "../CustomButton";
import { fetchLotById, lotsSelector } from "redux/lots/lotSlice";
import { useParams } from "@pankod/refine-react-router-v6";
import { deleteRule } from "redux/rules/ruleSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useToast } from "hooks";

const Rules = ({
  day,
  lotId,
  toggleModal,
  remainingHours,
}: {
  day: string;
  lotId: string;
  toggleModal: () => void;
  remainingHours: number;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { lot } = useAppSelector(lotsSelector);
  const params: any = useParams();
  const { toast } = useToast();

  const handleDeleteRule = ({ ruleId }: { ruleId: any }) => {
    const response = confirm("Are you sure you want to delete this Rule?");
    if (response) {
      dispatch(deleteRule({ ruleId: ruleId, toast: toast })).then(() =>
        setTimeout(() => {
          dispatch(fetchLotById({ lotId: lotId || params.id }));
        }, 700)
      );
    }
  };

  return (
    <Stack
      id="chart"
      justifyContent="center"
      alignItems="flex-start"
      boxShadow={1}
      bgcolor="#F8F8F8"
      p={2}
      borderRadius={2}
    >
      <Stack direction="row" gap={4}>
        <Typography variant="h6" gutterBottom component="div">
          Rules
        </Typography>
        <CustomButton
          disabled={remainingHours === 0 ? true : false}
          title="Add rule"
          handleClick={toggleModal}
          backgroundColor={"#475be8"}
          icon={<Add />}
        />
        <CustomButton
          disabled={remainingHours === 0 ? true : false}
          handleClick={() =>
            dispatch(fetchLotById({ lotId: lotId || params.id }))
          }
          backgroundColor={"#2c2c2c"}
          icon={<Refresh />}
        />
      </Stack>
      {lot?.rules && lot?.rules.length ? (
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell align="left">Rate (USD)</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lot.rules
              ?.filter((rule: any) => rule.day === day)
              .map((rule: any, index: any) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <p
                        style={{
                          background: rule?.color,
                          height: 10,
                          width: 10,
                          borderRadius: 10,
                          marginRight: 5,
                        }}
                        className="rounded-full h-3 w-3"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {rule?.start_time}
                    </TableCell>
                    <TableCell>{rule?.end_time}</TableCell>
                    <TableCell align="left" sx={{ fontWeight: 700 }}>
                      ${rule?.rate}
                    </TableCell>
                    {/* Delete Rule button */}
                    <TableCell align="left" sx={{ fontWeight: 600 }}>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <CustomButton
                          style={{
                            minWidth: 60,
                            height: 30,
                            fontSize: 14,
                            marginLeft: 1,
                          }}
                          handleClick={() =>
                            handleDeleteRule({ ruleId: rule?._id })
                          }
                          icon={<ClearSharp />}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="body2" color="gray" component="div">
          No Rules Yet
        </Typography>
      )}
    </Stack>
  );
};
export default Rules;
