export const calculateHours = (start: string, end: string) => {
  const startTime = new Date(`2000-01-01T${start}:00`);
  const endTime = new Date(`2000-01-01T${end}:00`);
  let diff = (endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60;

  if (diff < 0) {
    const midnight = new Date(`2000-01-01T00:00:00`);
    const midnightToEnd =
      (endTime.getTime() - midnight.getTime()) / 1000 / 60 / 60;
    const startToMidnight =
      (midnight.getTime() - startTime.getTime()) / 1000 / 60 / 60;
    diff = midnightToEnd + startToMidnight;
  }

  return diff;
};

export const getRandomHexColor = () => {
  const hexChars = "0123456789ABCDEF";
  let hexColor = "#";
  for (let i = 0; i < 6; i++) {
    hexColor += hexChars[Math.floor(Math.random() * 16)];
  }
  return hexColor;
};
