import { Stack, LinearProgress, Typography } from "@mui/material";
import { CustomLoading, SupervisorForm } from "components";
import { useAppSelector } from "redux/hooks";
import { lotsSelector } from "redux/lots/lotSlice";

const CreateSupervisor = () => {
  // Obtaining Lot [] data
  const { loading, stepForm } = useAppSelector(lotsSelector);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Stack spacing={2}>
      <Typography fontSize={25} fontWeight={700}>
        Create New Supervisor
      </Typography>

      {/* Stepper Indicator */}
      <SupervisorForm type={"create"} />
    </Stack>
  );
};

export default CreateSupervisor;
