"use client";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { collection, getDocs } from "firebase/firestore";
import { Close, History } from "@mui/icons-material";
import { Stack, LinearProgress, Typography, Modal, Box } from "@mui/material";
import { db } from "../firebase";
import {
  CustomButton,
  CustomLoading,
  UserHistoryGrid,
  UserHistoryModal,
} from "components";
import { usersColumns } from "components/columns";

const Users = () => {
  interface User {
    id: string;
    displayName: string;
    email: string;
    phone: string;
  }

  const [usersList, setUsersList] = useState<User[]>([
    { id: "", displayName: "", email: "", phone: "" },
  ]);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [selectedUserHistory, setSelectedUserHistory] = useState<User | null>(
    null
  );

  const [isLoading, setIsLoading] = useState(false);

  // fetch users data from firebase firestore
  useEffect(() => {
    const getUsers = async () => {
      try {
        setIsLoading(true);
        const querySnapshot = await getDocs(collection(db, "users"));
        const users: any = [];
        querySnapshot.forEach((doc: any) => {
          users.push({ ...doc.data(), id: doc.id });
        });
        setUsersList(users);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, []);

  const handleOpenHistoryModal = (params: any) => {
    if (typeof window !== "undefined") {
      setHistoryModalOpen(!historyModalOpen);
      setSelectedUserHistory(params.row);
    }
  };

  const detailsRows = usersList.map((item) => {
    return {
      id: item?.id,
      displayName: item?.displayName,
      email: item?.email,
      phone: item?.phone,
    };
  });
  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 150,
      renderCell: (params: any) => {
        // console.log(params);
        return (
          <Stack direction="row">
            <CustomButton
              title="History"
              handleClick={() => handleOpenHistoryModal(params)}
              backgroundColor="#475BE8"
              icon={<History fontSize="small" />}
            />
          </Stack>
        );
      },
    },
  ];

  if (isLoading) {
    return <CustomLoading />;
  }

  return (
    <Stack gap={2}>
      <Typography fontSize={25} fontWeight={700}>
        Users
      </Typography>

      <Stack bgcolor="#fcfcfc" padding={2} borderRadius={2} width="100%">
        <DataGrid
          autoHeight
          rows={detailsRows}
          columns={usersColumns.concat(actionColumn)}
          checkboxSelection={false}
        />
      </Stack>
      <UserHistoryModal
        historyModalOpen={historyModalOpen}
        selectedUserHistory={selectedUserHistory}
        toggleModal={() => setHistoryModalOpen(false)}
      />
      {/* <Modal open={historyModalOpen}>
        <Box className="modal_add_rule" width="80%">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            position="relative"
            width="100%"
          >
            <Typography fontSize={25} fontWeight={700}>
              History of{" "}
              <span style={{ color: "#000000", fontWeight: 900 }}>
                {selectedUserHistory?.displayName}
              </span>
            </Typography>
            <CustomButton
              handleClick={() => sethistoryModalOpen(false)}
              title=""
              icon={<Close />}
              style={{
                position: "absolute",
                right: 0,
                minWidth: "auto",
              }}
            />
          </Stack>
          <Stack
            bgcolor="#fcfcfc"
            padding={2}
            borderRadius={2}
            width="100%"
            boxShadow={2}
          >
            <UserHistoryGrid />
          </Stack>
        </Box>
      </Modal> */}
    </Stack>
  );
};

export default Users;
