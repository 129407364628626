import { useNotification } from "@pankod/refine-core";

const useToast = () => {
  const { open } = useNotification();

  const toast = {
    success(message: string) {
      open?.({
        type: "success",
        message,
        description: "Success",
        key: "1",
      });
    },
    error(message: string) {
      open?.({
        type: "error",
        message,
        description: "Error",
        key: "1",
      });
    },
  };

  return { toast };
};

export default useToast;
