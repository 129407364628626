import { ArrowCircleLeftRounded, Check } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";

import { Stack, Typography, LinearProgress } from "@mui/material";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { lotsSelector, setStepForm } from "redux/lots/lotSlice";
import CustomButton from "components/CustomButton";
import AddRules from "./AddRules";
import DayStepper from "./DayStepper";
import { CustomLoading } from "components";
import { useAppDispatch } from "redux/hooks";

const RulesForm = ({ type }: { type: string }) => {
  const navigate = useNavigate();

  // Obtaining Lot [] data
  const { lot, loading } = useSelector(lotsSelector);

  const dispatch = useAppDispatch();

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Stack
      bgcolor="#fff"
      px={2}
      py={4}
      borderRadius={2}
      boxShadow={2}
      overflow="hidden"
      spacing={4}
    >
      <form
        style={{
          flexDirection: "column",
          display: "flex",
          width: "100%",
        }}
      >
        <Typography fontWeight={600}>{lot?.alias}</Typography>
        <Typography
          fontSize={14}
          fontWeight={500}
          color="#000"
          marginBottom={3}
          letterSpacing={1}
          sx={{ opacity: 0.7 }}
        >
          {lot?.address}
        </Typography>
        <Stack spacing={4}>
          {/* Day Stepper  */}
          <DayStepper />

          {/* Add Rules and Pie Chart */}
          <AddRules lotId={lot?.id || lot?.lotId} />
          {/* <SpecialEvent lotId={lotId} type={type} /> */}
          {/* <EventForm lotId={lotId} activeEvent={lot.specialEvents} /> */}
          <Stack direction="row" gap={6}>
            {type === "edit" && (
              <CustomButton
                title="Back"
                handleClick={() => dispatch(setStepForm(0))}
                disabled={loading}
                icon={<ArrowCircleLeftRounded />}
                backgroundColor={"#2C2C2C"}
              />
            )}

            <CustomButton
              title="Finish"
              handleClick={() => navigate("/lots")}
              disabled={loading}
              icon={<Check />}
              backgroundColor={"#475be8"}
            />
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default RulesForm;
