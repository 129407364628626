import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { URL_PROVIDER } from "utils/env";

export interface RuleProps {
  _id?: string;
  start_time: string;
  end_time: string;
  rate: string;
  hours: number;
  color: string;
  lotId: "";
  day: string;
  __v?: number;
}

interface RuleState {
  rules: [];
  rule: RuleProps;
  isValidHours: boolean;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  stepDay: number;
}

const initialState: RuleState = {
  rules: [],
  rule: {
    start_time: "",
    end_time: "",
    rate: "",
    hours: 0,
    color: "",
    lotId: "",
    day: "",
  },
  isValidHours: false,

  loading: false,
  error: false,
  errorMessage: "",
  stepDay: 0,
};

const ruleSlice = createSlice({
  name: "rules",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRules: (state, { payload }) => {
      state.rules = payload;
      state.loading = false;
      state.error = false;
    },
    setRule: (state, { payload }) => {
      state.rule = payload;
      state.loading = false;
      state.error = false;
    },
    resetRule: (state) => {
      state.rule = initialState.rule;
    },
    setIsValidHours: (state, { payload }) => {
      state.isValidHours = payload;
    },

    setStepDay: (state, { payload }) => {
      state.stepDay = payload;
    },
    //   resetRule: (state)=>{
    //     state.rules =
    //   }
    // addRule: (
    //   state,
    //   action: PayloadAction<{ day: string; rule: RuleProps }>
    // ) => {
    //   const { day, rule } = action.payload;

    //   // Copiar el arreglo del día de la semana y agregar la nueva regla
    //   const updatedDayRules = [...state.rules[day], rule];

    //   // Actualizar el estado con el nuevo arreglo
    //   state.rules = {
    //     ...state.rules,
    //     [day]: updatedDayRules,
    //   };
    // },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setRules,
  setError,
  setLoading,
  setStepDay,
  setRule,
  resetRule,
} = ruleSlice.actions;
export const rulesSelector = (state: { rules: any }) => state.rules;

export default ruleSlice.reducer;

// fetch all rules
export function fetchRules() {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .get(`${URL_PROVIDER}/rules`)
      .then((response) => {
        dispatch(setRules(response.data));
        dispatch(setLoading(false));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {});
  };
}

//fetch specific lot rules
export function fetchLotRules({ lotId }: { lotId: string }) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .get(`${URL_PROVIDER}/lots/${lotId}`)
      .then((response) => {
        dispatch(setRules(response.data.rules));
        dispatch(setLoading(false));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {});
  };
}

// create Rule
export function createRule({
  rule,
  lotId,
  toast,
}: {
  rule: any;
  lotId: string;
  toast: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .post(`${URL_PROVIDER}/rules`, {
        ...rule,
        lotId: lotId,
      })
      .then((response) => {
        dispatch(setRules(response.data.savedRule));
        dispatch(setLoading(false));
        toast.success("Rule created successfully");
      })
      .finally(() => {
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error("An error happen Rule not created");
      });
  };
}

// DELETE METHOD to delete an existing rule
export function deleteRule({ ruleId, toast }: { ruleId: any; toast: any }) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .delete(`${URL_PROVIDER}/rules/${ruleId}`)
      .then((response) => {
        dispatch(setRules(response.data.savedRule));
        dispatch(setLoading(false));
        toast.success("Rule deleted successfully");
      })
      .finally(() => {
        dispatch(setLoading(false));
      })
      .catch((err) => {
        toast.error("An error happen Rule not deleted");
      });
  };
}
