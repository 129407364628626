import React, { useEffect } from "react";
import { useRouterContext } from "@pankod/refine-core";
import {
  AppBar,
  Avatar,
  Button,
  Stack,
  Toolbar,
  Typography,
} from "@pankod/refine-mui";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { adminSelector, setAdminUser } from "redux/admins/adminSlice";
import axios from "axios";
import { URL_PROVIDER } from "utils/env";

export const Header: React.FC = () => {
  const { adminUser } = useAppSelector(adminSelector);
  const { Link } = useRouterContext();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      axios
        .get(`${URL_PROVIDER}/auth/admin/user-data/`, {
          headers: {
            token: token,
          },
        })
        .then((userDataResponse) => {
          console.log(userDataResponse.data);

          dispatch(setAdminUser(userDataResponse.data.admin));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <AppBar
      color="default"
      position="sticky"
      elevation={0}
      sx={{ background: "#fcfcf" }}
    >
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button>
            <Link to="/profile">
              <Stack direction="row-reverse" gap={1} alignItems="center">
                <Avatar
                  sx={{
                    backgroundColor: adminUser.color,
                    width: 28,
                    height: 28,
                  }}
                >
                  <Typography
                    fontWeight={800}
                    fontSize={12}
                    fontStyle={{ color: "#fcfcfc" }}
                  >
                    {adminUser.initials}
                  </Typography>
                </Avatar>

                <Typography
                  fontWeight={800}
                  fontSize={12}
                  fontStyle={{ color: "#2c2c2c" }}
                  sx={{ textTransform: "lowercase" }}
                >
                  {adminUser.email}
                </Typography>
              </Stack>
            </Link>
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
