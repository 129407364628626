import { Refine, AuthProvider } from "@pankod/refine-core";
import { URL_PROVIDER } from "utils/env";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-mui";
import {
  AccountCircleOutlined,
  AssignmentInd,
  PeopleAltOutlined,
  VillaOutlined,
  AdminPanelSettings,
} from "@mui/icons-material";

import dataProvider from "@pankod/refine-simple-rest";
import routerProvider from "@pankod/refine-react-router-v6";
import axios, { AxiosRequestConfig } from "axios";

import { ColorModeContextProvider } from "contexts";
import { CredentialResponse } from "interfaces/google";

import {
  Login,
  Home,
  CreateSupervisor,
  EditSupervisor,
  Supervisors,
  CreateLot,
  EditLot,
  Lots,
  Users,
  Profile,
  Administrators,
  CreateAdministrator,
  EditAdministrator,
} from "pages";
import { Header, Layout, Sider, Title } from "components";
import { Provider, useDispatch } from "react-redux";
import { store } from "./redux/store";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { adminSelector, setAdminUser } from "redux/admins/adminSlice";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem("token");
  if (request.headers) {
    request.headers["Authorization"] = `Bearer ${token}`;
  } else {
    request.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return request;
});

function App() {
  const authProvider: AuthProvider = {
    login: async ({ credential }: CredentialResponse) => {},

    logout: () => {
      const token = localStorage.getItem("token");

      if (token && typeof window !== "undefined") {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        axios.defaults.headers.common = {};
        window.google?.accounts.id.revoke(token, () => {
          return Promise.resolve();
        });
      }
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
      const token = localStorage.getItem("token");

      if (token) {
        return Promise.resolve();
      }
      return Promise.reject();
    },

    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      const user = localStorage.getItem("user");
      if (user) {
        return Promise.resolve(JSON.parse(user));
      }
    },
  };

  return (
    <Provider store={store}>
      <ColorModeContextProvider>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <RefineSnackbarProvider>
          <Refine
            dataProvider={dataProvider(`${URL_PROVIDER}`)}
            notificationProvider={notificationProvider}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            resources={[
              {
                name: "lots",
                list: Lots,
                create: CreateLot,
                edit: EditLot,
                icon: <VillaOutlined />,
              },
              {
                name: "admins",
                list: Administrators,
                create: CreateAdministrator,
                edit: EditAdministrator,
                icon: <AdminPanelSettings />,
              },
              {
                name: "supervisors",
                list: Supervisors,
                create: CreateSupervisor,
                edit: EditSupervisor,
                icon: <AssignmentInd />,
              },
              {
                name: "users",
                list: Users,
                icon: <PeopleAltOutlined />,
              },

              {
                name: "profile",
                options: { label: "Account " },
                list: Profile,
                icon: <AccountCircleOutlined />,
              },
            ]}
            Title={Title}
            Sider={Sider}
            Layout={Layout}
            Header={Header}
            routerProvider={routerProvider}
            authProvider={authProvider}
            LoginPage={Login}
            // DashboardPage={Home}
          />
        </RefineSnackbarProvider>
      </ColorModeContextProvider>
    </Provider>
  );
}

export default App;
