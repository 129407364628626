import {
  Stack,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { Add } from "@mui/icons-material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  LotProps,
  fetchLots,
  lotsSelector,
  resetLot,
  setStepForm,
} from "redux/lots/lotSlice";
import { setStepDay } from "redux/rules/ruleSlice";
import { CustomButton, CustomLoading, LotTableBody } from "components";
import { useNavigate } from "@pankod/refine-react-router-v6";

const LotsPage = () => {
  const { lots, loading } = useAppSelector(lotsSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchLots());
  }, []);

  // Reset all the states
  useEffect(() => {
    dispatch(resetLot());
    dispatch(setStepForm(0));
    dispatch(setStepDay(0));
  }, []);

  if (loading) {
    return <CustomLoading />;
  }

  const TableCellTypography = ({ label }: { label: string }) => {
    return (
      <TableCell>
        <Typography color="#f0f0f0" letterSpacing={1} fontSize={14}>
          {label}
        </Typography>
      </TableCell>
    );
  };

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} spacing={4} justifyContent="space-between">
        <Typography fontSize={25} fontWeight={700}>
          Lots
        </Typography>

        <CustomButton
          title="Add"
          icon={<Add />}
          handleClick={() => navigate("/lots/create")}
        />
      </Stack>

      <TableContainer component={Paper} sx={{ marginBottom: 10 }}>
        <Table aria-label="collapsible table">
          <TableHead sx={{ backgroundColor: "#2c2c2c" }}>
            <TableRow>
              <TableCell />
              {TableCellTypography({ label: "Alias" })}
              {TableCellTypography({ label: "Address" })}
              {TableCellTypography({ label: "Supervisors" })}
              {TableCellTypography({ label: "Status" })}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(lots) &&
              lots.map((lot: LotProps) => (
                <LotTableBody key={lot?.address} lot={lot} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default LotsPage;
