import React, { useState } from "react";
import { FormControl, Typography, Select, MenuItem } from "@mui/material";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { lotsSelector } from "redux/lots/lotSlice";
import { setRule } from "redux/rules/ruleSlice";

const hours = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "24:00",
];

const TimeRange = ({ rule, currentDay }: { rule: any; currentDay: string }) => {
  const dispatch = useAppDispatch();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const { lot } = useAppSelector(lotsSelector);

  const handleStartTimeChange = (event: any) => {
    const newStartTime = event.target.value;
    setStartTime(newStartTime);
    dispatch(
      setRule({
        ...rule,
        start_time: newStartTime,
      })
    );
    if (newStartTime > endTime) {
      setEndTime("");
    }
  };

  const handleEndTimeChange = (event: any) => {
    const newEndTime = event.target.value;
    setEndTime(newEndTime);
    dispatch(
      setRule({
        ...rule,
        end_time: newEndTime,
      })
    );
  };

  // Obtaining used hours to avoid conflicts in hours range
  const usedHours = lot?.rules
    .filter((rule: { day: any }) => rule.day === currentDay) // Filtrar por el día actual
    .flatMap((rule: { start_time: string; end_time: string }) => {
      const startHour = rule.start_time.split(":")[0];
      const endHour = rule.end_time.split(":")[0];
      const excludedEndHour = parseInt(endHour) - 1; // Excluir la última hora
      return hours.slice(parseInt(startHour), excludedEndHour + 1);
    });

  return (
    <>
      <FormControl fullWidth required sx={{ gap: 2 }} focused>
        <Typography variant="caption">Start Time:</Typography>
        <Select
          name="start_time"
          value={startTime}
          onChange={handleStartTimeChange}
          fullWidth
          required
          variant="outlined"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {hours.map((hour: string, index: number) => (
            <MenuItem
              key={hour}
              value={hour}
              disabled={
                (rule?.end_time && hour >= rule?.end_time) ||
                (usedHours.includes(hour) && index !== usedHours.length - 1)
              }
            >
              {hour}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth required sx={{ gap: 2 }} focused>
        <Typography variant="caption">End Time:</Typography>
        <Select
          name="end_time"
          value={endTime}
          onChange={handleEndTimeChange}
          fullWidth
          required
          variant="outlined"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {hours.map((hour: string, index: number) => (
            <MenuItem
              key={hour}
              value={hour}
              disabled={
                (rule?.start_time && hour <= rule?.start_time) ||
                (usedHours.includes(hour) && index !== usedHours.length - 1)
              }
            >
              {hour}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default TimeRange;
