import { useState } from "react";
import { Email, Lock, Password, Person, Phone } from "@mui/icons-material";
import { Typography, Stack } from "@mui/material";
import { auth } from "../firebase";
import { CustomButton, ProfileInfo, UpdatePasswordModal } from "components";
import { useAppSelector } from "redux/hooks";
import { adminSelector } from "redux/admins/adminSlice";

const Profile = () => {
  const [openModal, setOpenModal] = useState(false);
  const { adminUser } = useAppSelector(adminSelector);
  const user: any = auth.currentUser;

  return (
    <Stack spacing={2}>
      <Typography fontSize={25} fontWeight={700}>
        Account
      </Typography>

      <ProfileInfo
        header={"Profile"}
        icon={<Person />}
        label={`${adminUser?.name} ${adminUser?.lastName}`}
      />

      <ProfileInfo
        header={"Phone Number"}
        icon={<Phone />}
        label={adminUser?.phoneLada}
      />
      <ProfileInfo
        header={"Email Address"}
        icon={<Email />}
        label={adminUser?.email}
      />
      {/* <ProfileInfo
          header={"Password"}
          icon={<Lock />}
          child={
            <CustomButton
              handleClick={() => setOpenModal(true)}
              title="Change Password"
              icon={<Password fontSize="small" />}
              backgroundColor="#475be8"
              style={{
                fontSize: 12,

                color: "#fcfcfc",
                minWidth: 200,
                flex: 1,
                boxShadow: 2,
                alignSelf: "flex-start",
              }}
            />
          }
        /> */}

      {/* <UpdatePasswordModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        user={user}
      /> */}
    </Stack>
  );
};

export default Profile;
