import { Stack, LinearProgress, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  fetchSupervisorById,
  supervisorsSelector,
} from "redux/supervisors/supervisorSlice";
import { useParams } from "@pankod/refine-react-router-v6";
import { CustomLoading, SupervisorForm } from "components";

const EditSupervisor = () => {
  // Obtaining Supervisor [] data
  const { loading } = useAppSelector(supervisorsSelector);
  const dispatch = useAppDispatch();
  const params: any = useParams();

  const fetchSupervisor = useCallback(() => {
    dispatch(fetchSupervisorById({ supervisorId: params.id }));
  }, [dispatch, params.id]);

  useEffect(() => {
    fetchSupervisor();
  }, [fetchSupervisor]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Stack spacing={2}>
      <Typography fontSize={25} fontWeight={700}>
        Edit Lot
      </Typography>
      <SupervisorForm type={"edit"} />
    </Stack>
  );
};

export default EditSupervisor;
