import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AddNewRuleModal, PieChart, Rules, SpecialEventForm } from "..";
import { rulesSelector } from "redux/rules/ruleSlice";
import { usePieChartValues } from "hooks";

const steps = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const AddRules = ({ lotId, type }: { lotId: string; type?: string }) => {
  const { stepDay } = useSelector(rulesSelector);
  const [selectedDay, setSelectedDay] = useState("monday");

  const [openModal, setOpenModal] = useState(false);
  const { series, colors, isValidHours, remainingHours } = usePieChartValues({
    selectedDay: selectedDay,
  });

  useEffect(() => {
    setSelectedDay(steps[stepDay]);
  });

  return (
    <>
      {selectedDay && (
        <>
          <Rules
            lotId={lotId}
            day={selectedDay}
            remainingHours={remainingHours}
            toggleModal={() => setOpenModal(!openModal)}
          />
          <PieChart
            remainingHours={remainingHours}
            series={series}
            colors={colors}
          />

          <SpecialEventForm lotId={lotId} />

          <AddNewRuleModal
            isValidHours={isValidHours}
            currentDay={selectedDay}
            rulesModal={openModal}
            lotId={lotId}
            toggleModal={() => setOpenModal(!openModal)}
          />
        </>
      )}
    </>
  );
};

export default AddRules;
