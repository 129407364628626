import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { useDispatch, useSelector } from "react-redux";
import { TodayOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { rulesSelector, setStepDay } from "redux/rules/ruleSlice";

const steps = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const DayStepper = () => {
  const { stepDay } = useSelector(rulesSelector);
  const dispatch = useDispatch();

  const handleStep =
    (step: number, label: React.SetStateAction<string>) => () => {
      dispatch(setStepDay(step));
    };

  return (
    <Stepper activeStep={stepDay} orientation="horizontal">
      {steps.map((label, index) => (
        <Step disabled={false} key={label} completed={false}>
          <StepButton
            disabled={false}
            onClick={handleStep(index, label)}
            style={{
              backgroundColor: index === stepDay ? "#000" : "#f0f0f0",
              borderRadius: 100,
              paddingTop: 2,
              paddingBottom: 2,
            }}
            icon={
              <TodayOutlined
                fontSize="small"
                style={{ color: index === stepDay ? "#fcfcfc" : "gray" }}
              />
            }
          >
            <Typography
              fontSize={12}
              fontWeight={600}
              color={index === stepDay ? "#fcfcfc" : "gray"}
              textTransform="capitalize"
            >
              {label.substring(0, 3)}
            </Typography>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default DayStepper;
