import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { URL_PROVIDER } from "utils/env";

export interface AdminUserProps {
  name: string;
  lastName: string;
  initials: string;
  color: string;
  email: string;
  password: string;
  phoneLada: string;
  id: string;
  isActive?: boolean;
  deletedAt?: Date;
}

interface AdminUserState {
  admins: AdminUserProps[];
  adminUser: AdminUserProps;
  admin: AdminUserProps;
  adminsList: [];
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

const initialState: AdminUserState = {
  admins: [],
  admin: {
    name: "",
    lastName: "",
    initials: "",
    color: "",
    email: "",
    phoneLada: "",
    id: "",
    password: "",
    isActive: false,
  },
  adminUser: {
    name: "",
    lastName: "",
    initials: "",
    color: "",
    email: "",
    phoneLada: "",
    id: "",
    password: "",
  },
  adminsList: [],
  loading: false,
  error: false,
  errorMessage: "",
};

const adminSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAdmins: (state, {payload}) => {
      state.admins = payload;
      state.loading = false;
      state.error = false;
    },
    setAdminUser: (state, { payload }) => {
      state.adminUser = payload;
      state.loading = false;
      state.error = false;
    },
    setAdmin: (state, { payload }) => {
      state.admin = payload;
      state.loading = false;
      state.error = false;
    },
    resetAdmin: (state) => {
      state.admin = initialState.admin;
    },
    setAdminsList: (state, { payload }) => {
      state.adminsList = payload;
      state.loading = false;
      state.error = false;
    },

    setError: (state) => {
      state.error = true;
    },
  },
});

export const { setAdmins, setAdminUser, setAdmin, setAdminsList, setError, setLoading, resetAdmin } = adminSlice.actions;
export const adminSelector = (state: { admin: any }) => state.admin;
export const adminsSelector = (state: { admins: any }) => state.admins;

export default adminSlice.reducer;

// create new Supervisor
export function signInAdmin({
  email,
  toast,
  password,
  navigate,
}: {
  email: string;
  toast: any;
  password: string;
  navigate: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .post(`${URL_PROVIDER}/auth/admin/sign-in`, {
        email,
        password,
      })
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("token", `${response.data.token}`);
        navigate("/");
        navigate(0);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.msg) {
          toast.error(err.response.data.msg); // Mostrar el mensaje de error del backend
        } else {
          toast.error("An error occurred"); // Mensaje de error genérico si no hay mensaje específico del backend
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };
}

// fetch all Admin
export function fetchAdmins() {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .get(`${URL_PROVIDER}/admins`)
      .then((response) => {
        dispatch(setAdmins(response.data));
        dispatch(setLoading(false));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {});
  };
}

export function fetchAdminById({
  adminId,
}: {
  adminId: string;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .get(`${URL_PROVIDER}/admins/${adminId}`)
      .then((response) => {
        dispatch(setAdmin(response.data));
        dispatch(setLoading(false));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {
        // toast.error("Error: supervisor not found, please try again");
      });
  };
}

// create new Admin
export function createAdmin({
  admin,
  toast,
}: {
  admin: AdminUserProps;
  toast: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .post(`${URL_PROVIDER}/admins`, {
        ...admin,
      })
      .then((response) => {
        dispatch(setAdmin(response.data));
        dispatch(setLoading(false));
        setTimeout(() => {
          dispatch(fetchAdmins()).then(() =>
            toast.success("New Admin created!")
          );
        }, 500);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          // Si hay un mensaje de error en la respuesta del backend, lo mostramos en el toast
          toast.error("Error: " + err.response.data);
        } else {
          // Si no hay un mensaje de error específico, mostramos un mensaje de error genérico
          toast.error("An error occurred");
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };
}

// update an existing Admin
export function updateAdmin({
  admin,
  adminId,
  toast,
}: {
  admin: AdminUserProps;
  adminId: string;
  toast: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .patch(`${URL_PROVIDER}/admins/${adminId}`, {
        ...admin,
      })
      .then((response) => {
        dispatch(setLoading(false));

        setTimeout(() => {
          dispatch(fetchAdmins()).then(() =>
            toast.success("Admin updated!")
          );
        }, 500);
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {
        if (err.response && err.response.data) {
          // Si hay un mensaje de error en la respuesta del backend, lo mostramos en el toast
          toast.error("Error: " + err.response.data);
        } else {
          // Si no hay un mensaje de error específico, mostramos un mensaje de error genérico
          toast.error("An error occurred");
        }
      });
  };
}

// delete a Admin
export function deleteAdmin({
  adminId,
  toast,
}: {
  adminId: string;
  toast: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .delete(`${URL_PROVIDER}/admins/${adminId}`)
      .then((response) => {
        dispatch(setLoading(false));
        setTimeout(() => {
          dispatch(fetchAdmins()).then(() =>
            toast.success("Admin deleted successfully!")
          );
        }, 500);
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {
        toast.error("An error occurred");
      });
  };
}
