import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@pankod/refine-mui";

export default function UserHistoryGrid({ data }: { data: any }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#2c2c2c" }}>
          <TableRow>
            <TableCell align="left" sx={{ color: "#fcfcfc" }}>
              Date (DD/MM/YY)
            </TableCell>
            <TableCell align="left" sx={{ color: "#fcfcfc" }}>
              Plate
            </TableCell>
            <TableCell align="left" sx={{ color: "#fcfcfc" }}>
              Lot
            </TableCell>
            <TableCell align="left" sx={{ color: "#fcfcfc" }}>
              From - To
            </TableCell>
            <TableCell align="left" sx={{ color: "#fcfcfc" }}>
              Payed Fee&nbsp;(USD)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: any) => {
            const milliseconds = item.created * 1000;
            const date = new Date(milliseconds);
            const options = {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              // second: 'numeric',
            };
            //@ts-expect-error
            const formattedDate = date.toLocaleString("en-EN", options);
            return (
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {formattedDate}
                </TableCell>
                <TableCell align="left"> {item?.metadata?.userPlate}</TableCell>

                <TableCell align="left">
                  {item?.metadata?.lot.split(",")[1].trim()}
                </TableCell>
                <TableCell align="left">
                  {" "}
                  {item.metadata.ruleStartTime} - {item.metadata.ruleEndTime}
                </TableCell>
                <TableCell align="left">$ {item?.amount / 100}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
