import React, { useState } from "react";
import {
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Stack,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const RulesCollapsible = ({
  rules,
  openAll = true,
}: {
  openAll?: boolean;
  rules?: any;
}): JSX.Element => {
  const daysOfWeek = [
    { title: "Monday", value: "monday" },
    { title: "Tuesday", value: "tuesday" },
    { title: "Wednesday", value: "wednesday" },
    { title: "Thursday", value: "thursday" },
    { title: "Friday", value: "friday" },
    { title: "Saturday", value: "saturday" },
    { title: "Sunday", value: "sunday" },
  ];

  const [open, setOpen] = useState<{ [key: string]: boolean }>({});
  const toggleCollapse = (day: { title: string; value: string }) => {
    setOpen((prevState) => ({
      ...prevState,
      [day.value]: !prevState[day.value],
    }));
  };

  //   if (loading) {
  //     return <LinearProgress color="inherit" />;
  //   }

  return (
    <Collapse in={openAll} timeout="auto" unmountOnExit>
      <Box px={4} py={1} bgcolor={"#fcfcfc"}>
        <Typography color="#000" fontWeight={600}>
          Rules
        </Typography>
        {daysOfWeek.map((day) => (
          <React.Fragment key={day.value}>
            {/* Days and individual button to open/close (collapsible) rules of the days */}
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <IconButton onClick={() => toggleCollapse(day)}>
                {open[day.value] ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
              <Typography fontSize={14} fontWeight={700} color="#475BE8">
                {day.title}
              </Typography>
              <Typography fontSize={14} fontWeight={700} color="#475BE8">
                ({rules?.filter((rule: any) => rule.day === day.value).length})
              </Typography>
            </Stack>

            {/* Rules of every day of the week  */}
            <Collapse in={open[day.value]}>
              {rules &&
              rules.length &&
              (rules?.filter((rule: any) => rule.day === day.value)).length ? (
                <Table
                  size="small"
                  aria-label="purchases"
                  sx={{ border: 1, borderColor: "#f0f0f0" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ opacity: 0.55 }}>From</TableCell>
                      <TableCell style={{ opacity: 0.55 }}>To</TableCell>
                      <TableCell style={{ opacity: 0.55 }}>Rate(USD)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rules
                      ?.filter((rule: any) => rule.day === day.value)
                      .map((rule: any, index: any) => {
                        return (
                          <TableRow key={index}>
                            <TableCell style={{ fontWeight: 700 }}>
                              {rule?.start_time}
                            </TableCell>
                            <TableCell style={{ fontWeight: 700 }}>
                              {rule?.end_time}
                            </TableCell>
                            <TableCell align="left" sx={{ fontWeight: 700 }}>
                              ${rule?.rate}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  fontSize={12}
                  color="red"
                  letterSpacing={2}
                  fontWeight={600}
                >
                  No rules yet
                </Typography>
              )}
            </Collapse>
          </React.Fragment>
        ))}
      </Box>
    </Collapse>
  );
};
export default RulesCollapsible;
