import { Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const PieChart = ({
  remainingHours,
  series,
  colors,
}: {
  remainingHours: number;
  series: Array<number>;
  colors: Array<string>;
}) => {
  return (
    <Stack
      id="chart"
      direction="row"
      justifyContent="center"
      alignItems="center"
      boxShadow={1}
      bgcolor="#F8F8F8"
      p={1}
      borderRadius={2}
    >
      <Stack direction="column" spacing={2}>
        <Stack alignItems="center">
          <Typography fontSize={12} color="#808191">
            Total Hours
          </Typography>
          <Typography fontSize={16} color="#11142d" fontWeight={700} mt={0}>
            24
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <Typography fontSize={12} color="#808191">
            Remaining Hours
          </Typography>
          {remainingHours === 0 ? (
            <Typography fontSize={12} color="#475BE8" fontWeight={700} mt={0}>
              No remaining hours for this day
            </Typography>
          ) : (
            <Typography fontSize={16} color="#f00" fontWeight={700} mt={0}>
              {remainingHours}
            </Typography>
          )}
        </Stack>
      </Stack>

      <ReactApexChart
        options={{
          chart: { type: "donut" },
          colors,
          legend: { show: true },
          dataLabels: {
            enabled: false,
          },
        }}
        series={series}
        type="donut"
      />
    </Stack>
  );
};

export default PieChart;
