import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { URL_PROVIDER } from "utils/env";
import { useToast } from "hooks";

export interface SupervisorProps {
  firebaseUID?: string;
  name: string;
  lastName: string;
  email: string;
  password: string;
  phone?: string;
  phoneLada: string;
  isActive?: boolean;
  hasAccess?: boolean;
  lastLogin?: Date;
  rememberToken?: string;
  deletedAt?: Date;
  status: boolean;
}

interface SupervisorState {
  supervisors: SupervisorProps[];
  supervisor: SupervisorProps;
  supervisorsList: [];
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

const initialState: SupervisorState = {
  supervisors: [],
  supervisor: {
    name: "",
    lastName: "",
    email: "",
    password: "",
    phoneLada: "",
    status: false,
  },
  supervisorsList: [],
  loading: false,
  error: false,
  errorMessage: "",
};

const supervisorSlice = createSlice({
  name: "supervisors",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSupervisors: (state, { payload }) => {
      state.supervisors = payload;
      state.loading = false;
      state.error = false;
    },
    setSupervisor: (state, { payload }) => {
      state.supervisor = payload;
      state.loading = false;
      state.error = false;
    },
    resetSupervisor: (state) => {
      state.supervisor = initialState.supervisor;
    },
    setSupervisorsList: (state, { payload }) => {
      state.supervisorsList = payload;
      state.loading = false;
      state.error = false;
    },

    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setSupervisors,
  setSupervisor,
  setSupervisorsList,
  setError,
  setLoading,
  resetSupervisor,
} = supervisorSlice.actions;
export const supervisorsSelector = (state: { supervisors: any }) =>
  state.supervisors;

export default supervisorSlice.reducer;

// fetch all supervisors
export function fetchSupervisors() {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .get(`${URL_PROVIDER}/supervisors`)
      .then((response) => {
        dispatch(setSupervisors(response.data));
        dispatch(setLoading(false));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {});
  };
}

export function fetchSupervisorById({
  supervisorId,
}: {
  supervisorId: string;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .get(`${URL_PROVIDER}/supervisors/${supervisorId}`)
      .then((response) => {
        dispatch(setSupervisor(response.data));
        dispatch(setLoading(false));
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {
        // toast.error("Error: supervisor not found, please try again");
      });
  };
}
// create new Supervisor
export function createSupervisor({
  supervisor,
  toast,
}: {
  supervisor: SupervisorProps;
  toast: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .post(`${URL_PROVIDER}/supervisors`, {
        ...supervisor,
      })
      .then((response) => {
        dispatch(setSupervisor(response.data));
        dispatch(setLoading(false));
        setTimeout(() => {
          dispatch(fetchSupervisors()).then(() =>
            toast.success("New Supervisor created!")
          );
        }, 500);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          // Si hay un mensaje de error en la respuesta del backend, lo mostramos en el toast
          toast.error("Error: " + err.response.data);
        } else {
          // Si no hay un mensaje de error específico, mostramos un mensaje de error genérico
          toast.error("An error occurred");
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };
}

// update an existing supervisor
export function updateSupervisor({
  supervisor,
  supervisorId,
  toast,
}: {
  supervisor: SupervisorProps;
  supervisorId: string;
  toast: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .patch(`${URL_PROVIDER}/supervisors/${supervisorId}`, {
        ...supervisor,
      })
      .then((response) => {
        dispatch(setLoading(false));

        setTimeout(() => {
          dispatch(fetchSupervisors()).then(() =>
            toast.success("Supervisor updated!")
          );
        }, 500);
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {
        if (err.response && err.response.data) {
          // Si hay un mensaje de error en la respuesta del backend, lo mostramos en el toast
          toast.error("Error: " + err.response.data);
        } else {
          // Si no hay un mensaje de error específico, mostramos un mensaje de error genérico
          toast.error("An error occurred");
        }
      });
  };
}
// delete a supervisor
export function deleteSupervisor({
  supervisorId,
  toast,
}: {
  supervisorId: string;
  toast: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .delete(`${URL_PROVIDER}/supervisors/${supervisorId}`)
      .then((response) => {
        dispatch(setLoading(false));
        setTimeout(() => {
          dispatch(fetchSupervisors()).then(() =>
            toast.success("Supervisor deleted successfully!")
          );
        }, 500);
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {
        toast.error("An error occurred");
      });
  };
}
