"use client";
import { Stack, Typography, Divider, Avatar, Box } from "@mui/material";

const ProfileInfo = ({
  header,
  icon,
  label,
  child,
}: {
  header: string;
  icon: any;
  label?: string;
  child?: any;
}) => {
  return (
    <Stack spacing={1} borderRadius={2} padding={3} bgcolor="#FCFCFC" flex={1}>
      {/* Image and user name */}
      <Typography fontSize={16} fontWeight={700} color="#626262">
        {header}
      </Typography>
      <Divider />

      <Stack direction="row" alignItems="center" spacing={2}>
        {icon}
        {label && (
          <Typography fontSize={14} color="#2c2c2c" fontWeight={600}>
            {label}
          </Typography>
        )}
        {child}
      </Stack>
    </Stack>
  );
};

export default ProfileInfo;
