import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";
import { RuleProps } from "../rules/ruleSlice";
import { EventProps } from "../events/eventSlice";
import { URL_PROVIDER } from "utils/env";

export interface LotProps {
  id: string;
  alias: string;
  address: string;
  latitude: string;
  longitude: string;
  rules: RuleProps[];
  specialEvents: EventProps[];
  supervisors: [];
  status: boolean;
}

interface LotState {
  lots: [];
  lot: LotProps;
  type: string;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  stepForm: number;
}

const initialState: LotState = {
  lots: [],
  lot: {
    id: "",
    alias: "",
    address: "",
    latitude: "",
    longitude: "",
    rules: [],
    supervisors: [],
    specialEvents: [],
    status: true,
  },
  type: "",
  loading: false,
  error: false,
  errorMessage: "",
  stepForm: 0,
};

const lotSlice = createSlice({
  name: "lots",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLots: (state, { payload }) => {
      state.lots = payload;
      state.loading = false;
      state.error = false;
    },
    setLot: (state, { payload }) => {
      state.lot = payload;
      state.loading = false;
      state.error = false;
    },
    resetLot: (state) => {
      state.lot = initialState.lot;
    },
    setStepForm: (state, action) => {
      state.stepForm = action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const { setLots, setLot, setError, setLoading, setStepForm, resetLot } =
  lotSlice.actions;
export const lotsSelector = (state: { lots: any }) => state.lots;

export default lotSlice.reducer;

// fetch all lots
export function fetchLots() {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .get(`${URL_PROVIDER}/lots`)
      .then((response) => {
        dispatch(setLots(response.data));
        dispatch(setLoading(false));
        // dispatch(setStepForm(1))
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {});
  };
}
// fetch lot by ID
export function fetchLotById({ lotId }: { lotId: string }) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .get(`${URL_PROVIDER}/lots/${lotId}`)
      .then((response) => {
        dispatch(setLot(response.data));
        dispatch(setLoading(false));
        // dispatch(setStepForm(1))
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {});
  };
}
// create new lot
export function createLot({ lot, toast }: { lot: LotProps; toast: any }) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .post(`${URL_PROVIDER}/lots`, {
        ...lot,
      })
      .then((response) => {
        dispatch(setLots(response.data));
        dispatch(setLot(response.data));
        dispatch(setLoading(false));
        dispatch(setStepForm(1));
        toast.success("New Lot created!");
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          // Si hay un mensaje de error en la respuesta del backend, lo mostramos en el toast
          toast.error("Error: " + err.response.data);
        } else {
          // Si no hay un mensaje de error específico, mostramos un mensaje de error genérico
          toast.error("An error occurred");
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };
}

// update an existing lot
export function updateLot({
  lotId,
  lot,
  toast,
}: {
  lotId: string;
  lot: LotProps;
  toast: any;
}) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .patch(`${URL_PROVIDER}/lots/${lotId}`, {
        ...lot,
      })
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(setStepForm(1));
        toast.success("Lot updated!");
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {
        toast.error("Error: " + err.message);
      });
  };
}
// delete a lot
export function deleteLot({ lotId, toast }: { lotId: string; toast: any }) {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    axios
      .delete(`${URL_PROVIDER}/lots/${lotId}`)
      .then((response) => {
        dispatch(setLoading(false));
        setTimeout(() => {
          dispatch(fetchLots()).then(() =>
            toast.success("Lot deleted successfully!")
          );
        }, 500);
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => {
        toast.success("Error: Lot can not deleted");
      });
  };
}
