import { Add, Delete, Edit } from "@mui/icons-material";

import {
  Stack,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { CustomButton, CustomLoading } from "components";
import { supervisorColumns } from "components/columns";
import { auth } from "../../firebase";

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  deleteSupervisor,
  fetchSupervisors,
  resetSupervisor,
  supervisorsSelector,
} from "redux/supervisors/supervisorSlice";
import { useToast } from "hooks";

const Supervisors = () => {
  const { supervisors, loading } = useAppSelector(supervisorsSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    dispatch(fetchSupervisors());
    dispatch(resetSupervisor());
  }, []);

  const handleDeleteSupervisor = ({
    supervisorId,
  }: {
    supervisorId: string;
  }) => {
    const response = confirm(
      "Are you sure you want to delete this Supervisor?"
    );
    if (response) {
      dispatch(deleteSupervisor({ supervisorId: supervisorId, toast: toast }));
    }
  };

  const user: any = auth.currentUser;
  const isResponsive = useMediaQuery("(max-width: 960px)");
  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 175,

      renderCell: (params: any) => {
        return (
          <Stack direction="row">
            <CustomButton
              fullWidth
              style={{ minWidth: 60, height: 30, fontSize: 12 }}
              title={isResponsive ? "" : "Edit"}
              handleClick={() => navigate(`/supervisors/edit/${params.id}`)}
              backgroundColor="#475BE8"
              icon={<Edit fontSize="small" />}
            />
            <CustomButton
              fullWidth
              style={{ minWidth: 60, height: 30, fontSize: 12, marginLeft: 1 }}
              title={isResponsive ? "" : "Delete"}
              handleClick={() => {
                handleDeleteSupervisor({
                  supervisorId: params.id,
                });
              }}
              backgroundColor="#FF0000"
              icon={<Delete fontSize="small" />}
            />
          </Stack>
        );
      },
    },
  ];

  const detailsRows = supervisors?.map((item: any) => {
    return {
      id: item.id,
      email: item.email,
      name: item.name,
      lastName: item.lastName,
      phone: item.phone,
      status: item.status,
      firebaseUID: item.firebaseUID,
    };
  });

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Stack gap={2}>
      <Stack direction={"row"} spacing={4} justifyContent="space-between">
        <Typography fontSize={25} fontWeight={700}>
          Supervisors
        </Typography>

        <CustomButton
          title="Add"
          icon={<Add />}
          handleClick={() => navigate("/supervisors/create")}
        />
      </Stack>
      <Stack bgcolor="#fcfcfc" padding={2} borderRadius={2} width="100%">
        <DataGrid
          autoHeight
          rows={detailsRows}
          columns={supervisorColumns.concat(actionColumn)}
          checkboxSelection={false}
        />
      </Stack>
    </Stack>
  );
};

export default Supervisors;
